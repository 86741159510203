import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import getEnv from '../utils/getEnv';

const { REACT_APP_AUTH_SERVICE_URL } = getEnv();

export interface User {
  auth0Identity?: string;
  email?: string;
  name?: {
    familyName: string;
    givenName: string;
  };
  anonymous: boolean;
  sessionID: string;
  email_verified?: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

export interface AuthContext {
  user: User | null;
  isLoading: boolean;
  isAuthenticated: boolean;
  logout: () => Promise<void>;
  login: () => Promise<void>;
  error: unknown;
  invoiceId: string;
  quoteId: string;
}

const AuthContext = createContext<AuthContext>({
  isLoading: true
} as AuthContext);

export const getId = (term: string): string | undefined => {
  const url = window.location.href;

  const regex = new RegExp(`/${term}/([a-zA-Z0-9]+)`);

  return url.match(regex)?.[1];
};

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [isLoading, setisLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<unknown>();
  const [defaultQuoteId, setQuoteId] = useState(
    sessionStorage.getItem('quoteId') || ''
  );
  const [defaultInvoiceId, setInvoiceId] = useState(
    sessionStorage.getItem('invoiceId') || ''
  );

  const quoteId =
    new URLSearchParams(location?.search).get('quote') || getId('terms');
  const invoiceId = getId('payment') || getId('complete');

  useEffect(() => {
    if (quoteId) {
      setQuoteId(quoteId);
      sessionStorage.setItem('quoteId', quoteId); // Set in session storage in order to use by other page like customer-portal
    }
    if (invoiceId) {
      setInvoiceId(invoiceId);
      sessionStorage.setItem('invoiceId', invoiceId);
    }
  }, [quoteId, invoiceId]);

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status

    const getAuthInfo = async () => {
      setisLoading(true);
      try {
        const res = await fetch(`${REACT_APP_AUTH_SERVICE_URL}/me`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            quoteId: quoteId || defaultQuoteId,
            invoiceId: invoiceId || defaultInvoiceId
          }
        });
        const result = await res.json();
        if (isMounted) {
          if (result && Object.keys(result).length !== 0) {
            result.email_verified =
              !result?.anonymous && (result?.email?.length ?? 0) > 0;
            setUser(result);
          }
          setisLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setError(error);
          setisLoading(false);
        }
      }
    };
    getAuthInfo();

    return () => {
      isMounted = false; // Cleanup function sets the flag to false on unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = async () => {
    window.location.href = `${REACT_APP_AUTH_SERVICE_URL}/logout`;
  };

  const login = async () => {
    window.location.href = `${REACT_APP_AUTH_SERVICE_URL}/auth0/login?redirectTo=${window.location.origin}`;
  };
  const value = useMemo(
    () => ({
      user,
      isAuthenticated: !!user && !user.anonymous,
      isLoading,
      logout,
      login,
      error,
      quoteId: quoteId || defaultQuoteId,
      invoiceId: invoiceId || defaultInvoiceId
    }),
    [
      user,
      isLoading,
      error,
      quoteId,
      defaultQuoteId,
      invoiceId,
      defaultInvoiceId
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContext => {
  return useContext(AuthContext);
};
