import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../auth/AuthProvider';
import Loading from '../../components/Loading';
import { useGetDocuSignEnvelopeSignedStatusByInvoiceIdLazyQuery } from '../../generated/graphql';
import CustomerAgreement from './CustomerAgreement';
import TnCSignedFlow from './TnCSignedFlow';

interface CompleteParams {
  invoiceId: string;
}

const Complete: React.FC = (props) => {
  const { isAuthenticated: isBrokerFlow } = useAuth();
  const propsObj = JSON.parse(JSON.stringify(props));
  const state = propsObj['history']?.['location']?.['state'];

  const { invoiceId } = useParams<CompleteParams>();

  const [
    getDocuSignEnvelopeSignedStatusByInvoiceIdQuery,
    {
      data: docusignData,
      error: getDocusignDataError,
      loading: getDocusignDataLoading
    }
  ] = useGetDocuSignEnvelopeSignedStatusByInvoiceIdLazyQuery({
    variables: {
      invoiceId
    }
  });

  useEffect(() => {
    if (isBrokerFlow) {
      getDocuSignEnvelopeSignedStatusByInvoiceIdQuery({
        variables: {
          invoiceId
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isBrokerFlow) {
    return <TnCSignedFlow flowKind={state?.flowKind} />;
  }

  if (getDocusignDataError) {
    return (
      <div data-testid="error-msg" className="m-auto text-lg text-center">
        {'Sorry, there&apos;s an error when we get terms&conditions status: '}
      </div>
    );
  }

  if (getDocusignDataLoading || !docusignData) {
    return <Loading />;
  }

  // Broker bind: TnC not signed

  const { getDocuSignEnvelopeSignedStatusByInvoiceId } = docusignData;
  const isDocuSignEnvelopeSigned =
    getDocuSignEnvelopeSignedStatusByInvoiceId?.isDocuSignEnvelopeSigned;

  // Checks if isDocuSignEnvelopeSigned exists, is not null, and is falsy
  if (
    typeof isDocuSignEnvelopeSigned !== 'undefined' &&
    isDocuSignEnvelopeSigned !== null &&
    !isDocuSignEnvelopeSigned
  ) {
    return <CustomerAgreement invoiceId={invoiceId} />;
  }

  return <TnCSignedFlow flowKind={state?.flowKind} />;
};

export const QuickComplete: React.FC = () => (
  <div className="m-auto text-lg text-center">
    <div>
      <p>
        <b className="text-xl">
          Thank you, your Policy details with Foxquilt have been updated.
        </b>
      </p>
      <p>
        If you have any questions, contact us at{' '}
        <a href="tel:+18888784588" className="underline">
          1-888-878-4588
        </a>{' '}
        or{' '}
        <a href="mailto:support@foxquilt.com" className="underline">
          support@foxquilt.com
        </a>
        .
      </p>
    </div>
  </div>
);

export default Complete;
